*,*::before,*::after{
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}

:root{
  --color-primary-1: hsl(116, 81%, 34%);
  --color-primary-1-dark: hsl(114, 65%, 20%);
  --color-primary-1-light: hsl(110, 43%, 42%);



  --color-gray-1: hsl(0, 0%, 15%);
  --color-gray-2: hsl(0, 0%, 38%);

  --color-gray-transparent1: hsla(0, 0%, 18%, 0.6);
  --color-gray-transparent2: hsla(0, 0%, 34%, 0.6);

  --color-gray-light-1: hsl(0, 0%, 80%);

  --gray: #565656;
  --darkgray: #313131;

  --transition: all 500ms ease;
  --transition-fast: all 350ms ease;
  --transition-slow: all 750ms ease;

  --container-width-big: 85%;
  --container-width-medium: 88%;
  --container-width-small:93%;

  --transition1: all 400ms ease;
  --transition2: all 200ms ease;

}

body{
  background-color: var(--color-gray-2);
  transition: var(--transition);
}
body.dark{
  background-color: var(--color-gray-1);
}
.content{
  padding-top: 10rem;
}
@font-face {
  font-family: 'GothamMedium';
  src: url('Assets/Fonts/Gotham/GothamMedium.ttf') format('truetype');
}
@font-face {
  font-family: 'GothamLight';
  src: url('Assets/Fonts/Gotham/GothamLight.ttf') format('truetype');
}
@font-face {
  font-family: 'GothamBold';
  src: url('Assets/Fonts/Gotham/GothamBold.ttf') format('truetype');
}
