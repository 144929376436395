
.lightControlPanel{
    width: var(--container-width-medium);
    margin-left: auto;
    margin-right: auto;
    font-family: "GothamMedium", Arial, sans-serif;
    font-size: 1.4rem;
    color: white;


}

.controlOption{
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: var(--color-gray-transparent1);
    padding: 1rem;
    margin-bottom: 0.75rem;
    border-radius: 0.5rem;
    transition: var(--transition);
}

body.dark .controlOption{
    background-color: var(--color-gray-transparent2);
}


.controlOption.controlOptionBig{
    flex-direction: column;
}

.controlOption.controlOptionBig .row {
    margin-bottom: 2rem;
    display: -webkit-flex;
    -webkit-flex-direction: row;
    justify-content: space-between;
    transition: var(--transition-slow);
    border-bottom: 1.5px solid #595959;
    padding-bottom: 2rem;
}


.controlOption.controlOptionBig .row:last-of-type,.controlOption.controlOptionBig .row:first-of-type{
    margin-bottom: 0;
    border-bottom: none;
    padding-bottom: 0;
}
.controlOption.controlOptionBig .row:nth-of-type(2){
    padding-top: 2rem;
}
.controlOption.controlOptionBig .row p{
    transition: var(--transition-slow);
    top:0;
}
.controlOption.controlOptionBig .row input{
    transition: var(--transition-slow);
}
.controlOption.controlOptionBig .row:last-of-type{
    margin-bottom: 0;
}
.controlOption .optionName{
    margin-top: auto;
    margin-bottom: auto;
    padding: 0.5rem;
    font-size: 1.4rem;
    color: white;
    -webkit-text-size-adjust: auto; /* Add this property to enable text size adjustment on iOS */
}
.timeValues{
    display: flex;
    flex-direction: column;
    text-align: right;
}
.controlOption .colorPicker{
    margin-top: .5rem;
}
.controlOption .colorPicker .IroBox,.controlOption .colorPicker .IroSlider,
.controlOption .colorPickerTimer .IroBox,.controlOption .colorPickerTimer .IroSlider{
    margin-left: auto;
    margin-right: auto;
}
.row .title{
    display: inline-block;
    margin-top: auto;
    margin-bottom: auto;
}
.optionName2{
    margin-top: auto;
    margin-bottom: auto;
    padding: 0.5rem;
    font-size: 1rem;
    width: 20%;
    color: white;
    -webkit-text-size-adjust: auto; /* Add this property to enable text size adjustment on iOS */
}


.timerOnOptions{
    width: 100%;
}



























.datetime-picker {
    font-family: Arial, sans-serif;
    margin: 2rem;
    width: 100%;
}

.custom-datepicker {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.5rem;
    font-size: 1rem;
    width: 100%;
}

.custom-datepicker.invalid {
    border: 1px solid #d31e1e;
    color: #d03535;
}

.custom-datepicker input {
    width: 180px;
    height: 36px;
    padding: 0.25rem;
    border: none;
    outline: none;
    background-color: #f5f5f5;
    color: #333;
    font-size: 1rem;
}
.custom-datepicker.invalid input {
    color: #d03535;

}
.custom-datepicker-popper {
    z-index: 9999;
}

.custom-datepicker-popper .react-datepicker__time-container {
    margin-top: 0.5rem;
}

.custom-datepicker-popper .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 100%;
}

.custom-datepicker-popper .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    padding: 0.25rem;
}

.custom-datepicker-popper .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    padding: 0.25rem;
    cursor: pointer;
}

.custom-datepicker-popper .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
    background-color: #f0f0f0;
}

.react-datepicker__week .react-datepicker__day--selected{
    background-color: #118311;
}

.react-datepicker__week .react-datepicker__day--selected:hover{
    background-color: #154b15;
}

.react-datepicker__month-container .react-datepicker__header{
    color: white;
    background-color: var(--color-gray-1);
}
.react-datepicker__month-container .react-datepicker__header .react-datepicker__day-name{
    color: white;
}

.react-datepicker__month-container .react-datepicker__header .react-datepicker__current-month{
    color: white;
}

.react-datepicker-popper .react-datepicker{
    border: none;
}



.react-datepicker__week .react-datepicker__day--keyboard-selected{
    background-color: #97dc97;
}

.react-datepicker__week .react-datepicker__day--keyboard-selected:hover{
    background-color: #83be83;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker .react-datepicker__triangle::after{
    border-bottom-color: #1daf1d;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker .react-datepicker__triangle::before{
    border-bottom-color: #1daf1d;
}

.row .datetime-picker{
    margin: 0.3rem;
}





.controlOption.column{
    flex-direction: column;
}

.select select {

    /* Reset Select */
    appearance: none;
    outline: 0;
    border: 0;
    box-shadow: none;
    /* Personalize */
    flex: 1;
    padding: 0 1em;
    color: #fff;
    background-color: var(--darkgray);
    background-image: none;
    cursor: pointer;
}
/* Remove IE arrow */
select::-ms-expand {
    display: none;
}
/* Custom Select wrapper */
.select.wide{
    width: 100%;
}
.select {
    position: relative;
    display: flex;
    width: 100%;
    height: 2.8rem;
    border-radius: .25em;
    overflow: hidden;
}
/* Arrow */
.select::after {
    content: '\25BC';
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.6rem;
    background-color: #345e3e;
    transition: .25s all ease;
    pointer-events: none;
}

.controlOption.hidden{
    display: none;
}
.controlOption .hidden,.controlOption .hidden *{
    display: none;
}
.colorPickerTimer{
    padding-top: 0.5rem;

}
@media screen and (max-width:800px){
    .controlOption .optionName{
        font-size: 1.2rem;
    }

    .controlOption .optionName2{
        font-size: 0.8rem;

    }
    .controlOption .row:first-of-type {
        -webkit-flex-direction: row;
        flex-direction: row;
    }
    .controlOption .row {
        -webkit-flex-direction: column;
        flex-direction: column;
    }

}